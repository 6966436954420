<template>
  <div>
    <div class="header-box pt-5 pb-4 mb-5">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-w-full col-xl-10">
          <div class="col-sm-12 mb-4">
            <WhiteLabel>
              <router-link
                :to="{ name: 'new-role' }"
                class="btn btn-orange btn-pill align-self"
                v-if="rolesPermission"
              >
                <i class="icon-plus"></i> Criar novo perfil
              </router-link>
            </WhiteLabel>
          </div>
          <form @submit.prevent="search" autocomplete="off" class="row mx-0">
            <div class="col-sm-12 col-lg-4 col-xl-4 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  v-model="roleFilter.name"
                  placeholder="Nome"
                  class="form-control"
                  name="name"
                  maxlength="80"
                />
              </div>
            </div>
            <div class="d-flex col-sm-12 col-md-6 col-lg-3 mb-3">
              <WhiteLabel class="mr-2">
                <button type="submit" class="btn btn-orange">Pesquisar</button>
              </WhiteLabel>
              <WhiteLabel class="ml-2">
                <button
                  type="button"
                  @click="resetFilter"
                  class="btn btn-outline-orange"
                >
                  Limpar filtro
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="role of roleList.roles"
          :key="role.id"
        >
          <div class="card table-card">
            <div class="card-content">
              <div class="d-flex mb-3">
                <div class="pull-left mr-auto">
                  <span
                    class="badge badge-pill"
                    :class="statusColor(role.active)"
                    >{{ role.active ? "ATIVO" : "INATIVO" }}</span
                  >
                </div>
                <div class="pull-right">
                  <p>PERFIL</p>
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="text-center mx-auto text-truncate">
                  <h3 class="font-weight-light mb-0">{{ role.name }}</h3>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="text-center mx-auto">
                  <div class="dropdown text-center" v-if="rolesPermission">
                    <WhiteLabel>
                      <button
                        type="button"
                        class="btn btn-outline-orange btn-pill dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Ações
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            href="#"
                            class="dropdown-item"
                            @click.prevent="toggleActivation(role)"
                            >{{ role.active ? "Inativar" : "Ativar" }}</a
                          >
                        </li>
                        <li>
                          <router-link
                            :to="{
                              name: 'edit-role',
                              params: { roleId: role.id },
                            }"
                            class="dropdown-item"
                            >Editar</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            :to="{
                              name: 'show-role',
                              params: { roleId: role.id },
                            }"
                            class="dropdown-item"
                            >Visualizar</router-link
                          >
                        </li>
                        <div class="dropdown-divider"></div>
                        <li>
                          <a
                            href="#"
                            class="dropdown-item"
                            @click.prevent="confirmRemove(role)"
                            >Excluir</a
                          >
                        </li>
                      </ul>
                    </WhiteLabel>
                  </div>
                  <router-link
                    :to="{ name: 'show-role', params: { roleId: role.id } }"
                    class="btn btn-outline-orange btn-pill"
                    v-if="!rolesPermission"
                    >Visualizar</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="roleList.count === 0">
        <div class="text-center">
          <h4>Nenhum perfil encontrado.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="roleList.count"
          :perPage="perPage"
          @exchange="turnPage"
          :page="page"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AccountApi from '@/services/AccountApi';
import { RoleFilter, RoleList } from '@/models/Role';
import { PermissionList } from '@/models/Permission';
import RoleError from '@/errors/RoleError';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppSearchRoles',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.askWhoAmI();
    await this.search().catch(() => {
      this.$bus.$emit('spinner-stop');
    });
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      roleList: new RoleList(),
      roleFilter: new RoleFilter(),
      permissionList: new PermissionList(),
      perPage: 12,
      page: 1,
      count: 0,
    };
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    rolesPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'account.roles'
      );
    },
    userName() {
      if (!this.whoAmI) return '';
      return this.whoAmI.user.fullName;
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    rolesPermission() {
      if (
        (this.permissionList.permissions[this.rolesPermissionIndex].grant ===
          'edit' ||
          this.isAccountHolder) &&
        this.isAccountActive
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
      exit: 'account/logout',
    }),
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    async activate(role) {
      const api = new AccountApi();
      this.$bus.$emit('spinner-run');
      await api.roles.activate(role);
      this.$alert.info(
        'Perfil ativo',
        `Perfil ${role.name} ativado com sucesso.`
      );
      await this.search();
      this.$bus.$emit('spinner-stop');
    },
    async deactivate(role) {
      const api = new AccountApi();
      this.$bus.$emit('spinner-run');
      await api.roles.deactivate(role);
      this.$alert.info(
        'Perfil inativo',
        `Perfil ${role.name} inativado com sucesso.`
      );
      await this.search();
      this.$bus.$emit('spinner-stop');
    },
    async confirmRemove(role) {
      this.$alert.question(
        'Excluir perfil',
        `Deseja realmente remover o perfil ${role.name}?`
      );
      this.$alert.onConfirm = async() => {
        await this.remove(role);
      };
    },
    resetFilter() {
      this.roleFilter = new RoleFilter();
    },
    statusColor(type) {
      return {
        'badge-success': type,
        'badge-danger': !type,
      };
    },
    async search() {
      const api = new AccountApi();
      this.$bus.$emit('spinner-run');
      this.roleList = await api.roles.search(this.roleFilter);
      this.roleList.roles = this.roleList.roles.slice(0, this.perPage);
      this.$bus.$emit('spinner-stop');
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.roleFilter.offset.index = inicio;
      await this.search();
    },
    async remove(role) {
      const api = new AccountApi();
      this.$bus.$emit('spinner-run');
      await api.roles.delete(role).then((result) => {
        if (result.error) {
          this.$alert.error(
            'Ops',
            `O Perfil ${role.name} já foi excluído anteriormente.`
          );
        } else {
          this.$alert.info(
            'Perfil excluído',
            `Perfil ${role.name} excluído com sucesso.`
          );
        }
        this.$bus.$emit('spinner-stop');
        this.search();
      });
    },
    async toggleActivation(role) {
      var label = role.active ? 'Inativar' : 'Ativar';
      this.$alert.question(
        `${label} perfil`,
        `${label} o perfil ${role.name}?`
      );
      this.$alert.onConfirm = async() => {
        if (role.active) {
          await this.deactivate(role);
        } else {
          await this.activate(role);
        }
      };
    },
  },
  components: {
    AppPaginator,
    WhiteLabel,
  },
};
</script>
