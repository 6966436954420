<template>
  <div class="mt-5">
    <div class="container">
      <form @submit.prevent="save" autocomplete="off">
        <div class="row justify-content-center">
          <div class="col-12 col-w-full col-lg-8">
            <div class="card">
              <div class="d-flex">
                <h3
                  class="font-weight-light ml-3 pull-left"
                >{{ $route.params.roleId ? 'Editar perfil' : 'Criar novo perfil' }}</h3>
                <div class="pull-right ml-auto mr-3">
                  <WhiteLabel>
                    <label class="switch switch-danger switch-to-success">
                      <span class="mr-2">{{ role.active ? 'Ativo' : 'Inativo' }}</span>
                      <input type="checkbox" v-model="role.active" />
                      <span class="switch-slider"></span>
                    </label>
                  </WhiteLabel>
                </div>
              </div>
              <hr />
              <div class="card-content">
                <div class="form-row">
                  <div class="col-12 col-w-full mb-5">
                    <div class="form-group">
                      <label>Qual o nome do perfil?*</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="role.name"
                        placeholder="Digite aqui o nome do perfil"
                        maxlength="80"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <h4 class="font-weight-light ml-3 pull-left">Selecionar os níveis de permissões:</h4>
              </div>
              <hr />
              <div class="card-content">
                <div class="row mb-4">
                  <app-edit-permissions
                    class="col-12 col-w-full col-md-6"
                    v-for="permission in permissionList.permissions"
                    :permission="permission"
                    :key="permission.name"
                    @app-edit-permissions="changePermission"
                  />
                </div>
              </div>
              <div class="d-flex mb-4">
                <WhiteLabel class="m-auto">
                  <router-link
                    :to="{name: 'roles'}"
                    class="btn btn-md btn-outline-orange mr-2 ml-auto"
                  >Cancelar</router-link>

                  <button
                    type="submit"
                    class="btn btn-md btn-orange mr-auto"
                    :disabled="role.name === ''"
                  >Salvar</button>
                </WhiteLabel>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AccountApi from '@/services/AccountApi';
import AppEditPermissions from './EditPermissions';
import { findRoleMixins } from './RoleMixins';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppEditRole',
  computed: {
    customersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        x => x.name === 'business.customers'
      );
    },
    rolesPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        x => x.name === 'account.roles'
      );
    },
    usersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        x => x.name === 'account.users'
      );
    },
    paymentsPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        x => x.name === 'payments'
      );
    },
    anticipationPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        x => x.name === 'payments.anticipation'
      );
    },
    creditPurchasePermissionIndex() {
      return this.permissionList.permissions.findIndex(
        x => x.name === 'payments.credits'
      );
    },
    invoicePermissionIndex() {
      return this.permissionList.permissions.findIndex(x => x.name === 'payments.invoice');
    },
    cardBrandPermissionIndex() {
      return this.permissionList.permissions.findIndex(x => x.name === 'payments.cardBrand');
    },
    dashboardPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        x => x.name === 'paymentAccounts.balance'
      );
    },
  },
  methods: {
    async save() {
      this.$bus.$emit('spinner-run');
      const api = new AccountApi();

      if (this.$route.params.roleId) {
        this.role = await api.roles.update(this.role);
        await api.scopes.revoke(this.role, this.permissionList.revokedScopes());
      } else {
        this.role = await api.roles.create(this.role);
      }

      await api.scopes.grant(this.role, this.permissionList.grantedScopes());

      this.$bus.$emit('spinner-stop');
      this.$router.push({ name: 'roles' });
    },
    changePermission() {
      if (
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' &&
        this.permissionList.permissions[this.customersPermissionIndex].grant ===
          'none'
      ) {
        this.permissionList.permissions[this.customersPermissionIndex].grant =
          'edit';
      }

      if (
        this.permissionList.permissions[this.invoicePermissionIndex].grant !==
          'none' &&
        this.permissionList.permissions[this.cardBrandPermissionIndex].grant ===
          'none'
      ) {
        this.permissionList.permissions[this.cardBrandPermissionIndex].grant =
          'readonly';
      }

      if (
        this.permissionList.permissions[this.dashboardPermissionIndex].grant !==
          'none' &&
        (this.permissionList.permissions[this.cardBrandPermissionIndex]
          .grant === 'none' ||
          this.permissionList.permissions[this.invoicePermissionIndex].grant ===
            'none')
      ) {
        this.permissionList.permissions[this.cardBrandPermissionIndex].grant =
          'readonly';
        this.permissionList.permissions[this.invoicePermissionIndex].grant =
          'readonly';
      }
    },
  },
  components: {
    AppEditPermissions,
    WhiteLabel,
  },
  mixins: [findRoleMixins],
};
</script>
