import { render, staticRenderFns } from "./EditPermissions.vue?vue&type=template&id=a5b5c79c&scoped=true"
import script from "./EditPermissions.vue?vue&type=script&lang=js"
export * from "./EditPermissions.vue?vue&type=script&lang=js"
import style0 from "./EditPermissions.vue?vue&type=style&index=0&id=a5b5c79c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5b5c79c",
  null
  
)

export default component.exports