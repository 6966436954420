<template>
  <div>
    <WhiteLabel>
      <h5 class="row ml-0 font-weight-light text-orange mb-2">
        {{ permission.info.title }}*:
        <em
          class="icon info-icon gray-2"
          data-toggle="tooltip"
          data-html="true"
          data-placement="bottom"
          v-if="showInfoTooltip"
          :title="message"
        ></em>
      </h5>
    </WhiteLabel>

    <div class="form-group mb-4">
      <select
        class="form-control"
        name="permission"
        v-model="permission.grant"
        @change="onChange()"
      >
        <option
          v-for="grantType in filteredGrantTypes"
          :key="`${permission.name}:${grantType}`"
          :value="grantType"
        >{{ permission.info[grantType] }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { Permission } from '@/models/Permission';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppEditPermissions',
  components: {
    WhiteLabel,
  },
  data() {
    return {
      message: '',
    };
  },
  methods: {
    onChange() {
      this.$emit('app-edit-permissions');
    },
  },
  async mounted() {
    /* eslint-disable no-undef */
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },
  props: {
    permission: {
      type: Permission,
      default: new Permission(),
      required: true,
    },
  },
  computed: {
    showInfoTooltip() {
      switch (this.permission.name) {
      case 'payments':
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.message =
            'Para permitir que o usuário realize transações é necessário que ele tenha acesso a clientes';
        return true;
      case 'invoice':
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.message =
            'Para permitir que o usuário realize consultas é necessário que ele tenha acesso ao gerenciamento de bandeiras de cartão de crédito';
        return true;
      case 'paymentAccounts.balance':
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.message =
            'Para permitir que o usuário tenha acesso aos indicadores é necessário que ele tenha acesso ao gerenciamento de bandeiras de cartão de crédito, consulta de transferências da conta de pagamento e consulta VAN Banese';
        return true;
      default:
        return false;
      }
    },
    filteredGrantTypes() {
      return this.permission.grantTypes.filter(grantType => {
        if (this.permission.info[grantType] !== '') {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.icon.info-icon {
  height: 1rem;
  margin-left: 1rem;
}
</style>
