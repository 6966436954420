import AccountApi from '@/services/AccountApi';
import { PermissionList } from '@/models/Permission';
import { Role } from '@/models/Role';

const findRoleMixins = {
  data() {
    return {
      role: new Role(),
      permissionList: new PermissionList(),
    };
  },
  async mounted() {
    if (this.$route.params.roleId) {
      await this.find();
    }

    $(() => $('[data-toggle="popover"]').popover());
  },
  methods: {
    async find() {
      this.$bus.$emit('spinner-run');

      const api = new AccountApi();
      this.role = await api.roles.find(this.$route.params.roleId);
      this.permissionList.fromScopes(this.role.scopes);

      this.$bus.$emit('spinner-stop');
    },
  },
};

export { findRoleMixins };
