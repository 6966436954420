<template>
  <div class="mt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-w-full col-lg-8">
          <div class="card">
            <WhiteLabel class="d-flex">
              <h3 class="font-weight-light ml-3 pull-left">{{ role.name }}</h3>
              <router-link
                :to="{ name: 'roles' }"
                class="ml-auto mt-1 pull-right mr-4"
              >
                <i class="icon-arrow-left-circle"></i> Voltar
              </router-link>
            </WhiteLabel>

            <hr />

            <div class="card-content">
              <div class="text-center mb-5">
                <WhiteLabel>
                  <label class="marker mb-2">
                    <big>{{ role.totalUsers }}</big>
                  </label>
                </WhiteLabel>
                <h4 class="font-weight-light">Usuários no perfil</h4>
              </div>
            </div>
            <div class="d-flex">
              <h4 class="font-weight-light ml-3 pull-left">
                Selecionar os níveis de permissões:
              </h4>
            </div>
            <hr />
            <div class="card-content">
              <div class="row mb-2">
                <app-show-permissions
                  class="col-12 col-w-full col-md-6 mb-3"
                  v-for="permission in permissionList.permissions"
                  :permission="permission"
                  :key="permission.name"
                />
              </div>

              <WhiteLabel class="d-flex mb-4">
                <router-link
                  :to="{ name: 'edit-role' }"
                  class="btn btn-md btn-orange mx-auto"
                  v-if="rolesPermission"
                  >Editar perfil</router-link
                >
              </WhiteLabel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { findRoleMixins } from '@/components/access-control/roles/RoleMixins';
import { PermissionList } from '@/models/Permission';
import AppShowPermissions from './ShowPermissions';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppShowRole',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.askWhoAmI();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      permissionList: new PermissionList(),
    };
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    rolesPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'account.roles'
      );
    },
    userName() {
      if (!this.whoAmI) return '';
      return this.whoAmI.user.fullName;
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    rolesPermission() {
      if (
        (this.permissionList.permissions[this.rolesPermissionIndex].grant ===
          'edit' ||
          this.isAccountHolder) &&
        this.isAccountActive
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
      exit: 'account/logout',
    }),
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
  },
  components: {
    AppShowPermissions,
    WhiteLabel,
  },
  mixins: [findRoleMixins],
};
</script>
