<template>
  <div>
    <WhiteLabel>
      <h5 class="font-weight-light text-orange mb-2">
        {{ permission.info.title }}:
      </h5>
    </WhiteLabel>
    <p v-if="permission.grant === 'none'">
      {{ permission.info[permission.grant] }}
    </p>
    <p v-if="permission.grant === 'readonly'">
      {{ permission.info[permission.grant] }}
    </p>
    <p v-if="permission.grant === 'edit'">
      {{ permission.info[permission.grant] }}
    </p>
    <p v-if="permission.grant === 'create'">
      {{ permission.info[permission.grant] }}
    </p>
  </div>
</template>

<script>
import { Permission } from '@/models/Permission';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppShowPermissions',
  components: {
    WhiteLabel,
  },
  props: {
    permission: {
      type: Permission,
      default: new Permission(),
      required: true,
    },
  },
};
</script>
